.menu > ul > li > a.active {
  border-bottom: 3px solid #26b571;
}

@media only screen and (min-width: 990px) {
  .menu > ul > li > a {
    padding-bottom: 2px !important;
  }
}

@media only screen and (max-width: 990px) {
  .menu > ul > li > a {
    padding: 20px 30px !important;
  }
}
